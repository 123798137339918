import { useStaticQuery, graphql } from 'gatsby';

const FixedPageGet = (pageid: string) => {
  const shopdataquery = useStaticQuery(graphql
    `query{
        allMicrocmsFixedpageYrph (sort: {fields: sortIndex, order: ASC}){
          edges {
            node {
              title
              fixedpageYrphId
              contents_group{
                contents {
                  fieldId
                  heading_text
                  section_title
                  section_subtitle
                  wysiwyg
                  box
                  caption
                  label
                  table
                  youtube
                  htmlcode
                  type
                  align
                  image {
                    url
                    width
                    height
                  }
                  images {
                    caption
                    image {
                      url
                      width
                      height
                    }
                    link {
                      href
                      blank
                    }
                  }
                  keyvalue {
                    fieldId
                    keyname
                    value
                    value2
                  }
                  list{
                    value
                  }
                  tile{
                    image {
                      url
                      width
                      height
                    }
                    heading_text
                    wysiwyg
                  }
                  card {
                    title
                    text
                    note
                    image{
                      url
                      width
                      height
                    }
                    btn {
                      fieldId
                      label
                      link {
                        blank
                        href
                      }
                      margin {
                        margin
                      }
                      pdf {
                        url
                      }
                    }
                  }
                  link {
                    href
                    blank
                  }
                  pdf {
                    url
                  }
                  margin {
                    margin
                  }
                  accordion {
                    title
                    contents {
                      fieldId
                      heading_text
                      wysiwyg
                      box
                      caption
                      label
                      table
                      youtube
                      htmlcode
                      type
                      align
                      image {
                        url
                        width
                        height
                      }
                      link {
                        href
                        blank
                      }
                      pdf {
                        url
                      }
                      margin {
                        margin
                      }
                    }
                  }
                  left {
                    fieldId
                    heading_text
                    wysiwyg
                    box
                    caption
                    label
                    table
                    youtube
                    htmlcode
                    type
                    align
                    image {
                      url
                      width
                      height
                    }
                    keyvalue {
                      fieldId
                      keyname
                      value
                      value2
                    }
                    link {
                      href
                      blank
                    }
                    pdf {
                      url
                    }
                    margin {
                      margin
                    }
                  }
                  right {
                    fieldId
                    heading_text
                    wysiwyg
                    box
                    caption
                    label
                    table
                    youtube
                    htmlcode
                    type
                    align
                    image {
                      url
                      width
                      height
                    }
                    keyvalue {
                      fieldId
                      keyname
                      value
                      value2
                    }
                    link {
                      href
                      blank
                    }
                    pdf {
                      url
                    }
                    margin {
                      margin
                    }
                  }
                }
              }
              
            }
          }
        }
      }`
  )
  const shopdata = shopdataquery.allMicrocmsFixedpageYrph.edges
  return shopdata.filter((item: any) => {
    if (item.node.fixedpageYrphId == pageid) {
      return true
    } else {
      return false
    }
  })[0].node
}

export default FixedPageGet