import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
} from '../../../components/_index';
import fixedPageGet from '../../../utils/fixedPage-get';
import FixedPageLayout from '../../../components/_extra/fixedpage_layout';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: <>LANDMARK SPA</>,
            sub: 'ランドマークスパ',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/facilities/landmark_spa/kv.png',
              },
              imgSp: {
                src: '/assets/images/facilities/landmark_spa/kv__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/facilities/landmark_spa/kv02.png',
              },
              imgSp: {
                src: '/assets/images/facilities/landmark_spa/kv02__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '館内施設・サービス',
                path: '/facilities/',
              },
            ],
            current: {
              label: 'ランドマークスパ',
            },
          }}
        />
      </CJumbotron>
      <FixedPageLayout data={fixedPageGet('landmark-spa')} />
      <section className="u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
